<template>
    <div class="reg">
        <div class="regtop flexab ">
            <img :src="utils.loadImg('logo2.png')" alt="" height="26" class="point" @click="$router.replace({ name: 'login' })" />
            <!-- <div class="rig flex flexa padr40 "> -->
            <div class="rig flex flexa padr40 point" @click="tab=2">
                <img :src="utils.loadImg('ma.png')" alt="" width="25" height="25" />
            </div>
        </div>

        <div v-if="tab==1" class="regmid bgf">
            <div class="tc midhead borbot">
                <p class="font12 lef">已有账号？<router-link to="/login" class="colzhuti">立即登录</router-link> </p>
                <p class="col24 font22 fontw6">欢迎注册海量数据 | 工单系统</p>
            </div>
            <div class="wmid">
                <p class="h40"></p>
                <el-form
                    :model="param"
                    :rules="rules"
                    ref="submitRef"
                    class="demo-ruleForm"
                >
                    <el-form-item  prop="username">
                        <el-input placeholder="用户名" v-model="param.username" size="large"/>
                    </el-form-item>

                    <el-form-item  prop="email">
                        <el-input placeholder="邮箱号" v-model="param.email" size="large"/>
                    </el-form-item>

                    <el-form-item  prop="mobile">
                        <el-input placeholder="请输入您的手机号" v-model="param.mobile" size="large"/>
                    </el-form-item>

                    <el-form-item  prop="password">
                        <el-input placeholder="输入密码" v-model="param.password" show-password size="large" type="password" />
                    </el-form-item>

                    <el-form-item  prop="confirmPassword">
                        <el-input v-model="param.confirmPassword" placeholder="再次输入密码" show-password size="large" type="password" />
                    </el-form-item>

                    <el-form-item  prop="authCode">
                        <div class="flex nowrap">
                            <el-input v-model="param.authCode" placeholder="输入6位验证码" size="large"/>
                            <el-button @click="handleGetSms" size="large" class="btn1">{{yzmSend ? '重新发送('+ time+'s)' : '发送验证码'}}</el-button>
                        </div>
                    </el-form-item>
                </el-form>
                <el-input v-model="param.csi" placeholder="客户服务号（CSI）（非必填）" size="large"/>

                <p class="col98 font10 fontw4 lh16 padt10">*选填 公司在购买产品后都有唯一的客户服务号（CSI），只有关联了CSI号且CSI在有效期内的账户才能提交对应的技术工单</p>
                <div class="padt40 padb24">
                    <el-checkbox v-model="checked"  class="col716e font12">
                        我已阅读并同意
                        <el-link @click="$router.push({ name: 'userAgreement' })" :underline="false" class="colblue">《隐私政策》</el-link>
                        <el-link @click="$router.push({ name: 'privacyPolicy' })" :underline="false" class="colblue">《海量数据服务协议》</el-link>
                    </el-checkbox>
                </div>
                <el-button @click="submit" type="primary" class="w100" size="large" >
                    立即注册
                </el-button>
            </div>
        </div>
        <div v-if="tab==2" class="regmid bgf">
            <div class="tc midhead flexa flexc flex borbot">
                <img :src="utils.loadImg('wx.png')" alt="" />
                <p class="col24 font22 fontw6 padl14">微信扫码关联</p>
            </div>
            <div class="wmid tc">
                <div style="height:50px"></div>
                <p class="col24 font14 lh30">扫码关联微信以方便您</p>
                <p class="col24 font14 lh30 padb20">使用微信关联账号，接受工单消息提醒</p>

                <img :src="utils.loadImg('ma2.png')" alt="" srcset="">

                <p @click="tab=1" class="colzhuti font14 point padt30">暂不设置</p>
            </div>
        </div>
        <p style="height: 137px;"></p>
        <div class="botxt">
            <p class="col6 tc font12 padt40 lh20">版权所有：Copyright 2021 版权所有 海量数据</p>
            <p class="col6 tc font12 lh20">京ICP备10213903号-3 京公网安备11010802016346</p>
        </div>
    </div>
</template>
<script>
import { registered, sendVerificationCode } from "@/utils/api1.js"
import { checkPasswd } from '@/utils/utils'

export default {
    data(){
        return{
            tab: 1,
            param:{
                mobile: '',
                authCode: '',            
            },
            checked: true,
            password:'',
            authCode: '',            
            time:60,
            yzmSend: false,
            timmer: null,
            rules:{
                mobile: [
					{ required: true, message: '请输入手机号', trigger: 'blur' },
					{
                        validator(rule, value, callback) {
                            const _m = /^1(3|4|5|6|7|8|9)\d{9}$/.test(value)
                            if (_m) {
                                return callback()
                            }
                            return callback(new Error('请输入正确的手机号'))
                        }, trigger: 'blur'
                    }
				],
                password: [
					{ required: true, message: '请输入密码', trigger: 'blur' },
					{
						validator: (rule, value, callback) => {
							if (this.param.confirmPassword) {
								if (value != this.param.confirmPassword) {
									return callback(new Error("两次输入密码不一致"));
								} else {
									return callback();
								}
							}
							return callback();
						}, trigger: ['blur']
					},
				],
				confirmPassword: [
					{ required: true, message: '请输入确认密码', trigger: 'blur' },
					{
						validator: (rule, value, callback) => {
							if (this.param.password) {
								if (value != this.param.password) {
									return callback(new Error("两次输入密码不一致"));
								} else {
									return callback();
								}
							}
							return callback()
						}, trigger: ['blur']
					},
                ],
                username: [ { required: true, message: '请输入用户名！', trigger: 'blur', }, ],
                authCode: [ { required: true, message: '请输入验证码', trigger: 'blur', }, ],
                email: [
                    { required: true, message: '请输入邮箱', trigger: 'blur' },
                    {
                        validator(rule, value, callback) {
                            const _e = new RegExp("^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$").test(value)
                            if (_e) {
                                return callback()
                            }
                            return callback(new Error('请输入正确邮箱号'))
                        }, trigger: 'blur'
                    }
                ]
                // email: [ { validator: checkEmail, trigger: 'blur' }],
                // mobile: [ { validator: checkMobile, trigger: 'blur' }],
                // password: [ { required: true, message: '请输入密码！', trigger: 'blur', }, ],
                // yzm: [ { required: true, message: '请输入密码！', trigger: 'blur', }, ],
            },
        }
    },
    methods: {
        async submit(){
            try {
                if(!this.checked){
                    return this.$message.warning('请勾选 “我已阅读并同意《隐私政策》《海量数据服务协议》” ！');
                }
                await this.$refs.submitRef.validate()
                const res = await this.$api.login.registerUser(this.param)
                if(res){
                    this.$message.success("注册成功，请登录");
                    this.$router.push('/login')
                }
            } catch (e) {
                return console.log(e)
            }
        },
       
        handleGetSms() {
            let that = this;
            console.log('sss');
            if (that.yzmSend) return;
            console.log('s12ss');

            this.$api.login.getAuthCode({mobile:that.param.mobile}).then(res=>{
                console.log(res);
                if(res){
                    that.$message.success("验证码发送成功！");
                }
            })
            
            if(!that.yzmSend){
                that.yzmSend = true;
                that.timmer = setInterval(function () {
                    if (that.time == 1) {
                        that.time = 60;
                        that.yzmSend = false;
                        clearInterval(that.timmer);
                        return;
                    }
                    that.time--;
                }, 1000);
            }
        },
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            const state = history.state
            if (state.tab) {
                vm.tab = Number(state.tab)
            }
        })
    }
}
</script>
<style lang="scss" scoped>
.botxt{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: 37px;
    box-sizing: border-box;
}
.regtop{
    background: #fff;
    height: 50px;
    padding: 0 25px;
    position: fixed;
    top: 0;
    width: 100%;
    min-width: 1090px;
    z-index: 10;
    box-sizing: border-box;
}
.reg{
    padding: 90px 0 0px;
    box-sizing: border-box;
    min-height: 100vh;
    background: #F6F6F6;
    position: relative;
    .regmid{
        width: 1090px;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 0 64px 57px;
    }
    .wmid{
        width: 320px;
        margin: 0 auto;
    }
    .midhead{
        line-height: 100px;
        position: relative;
        .lef{
            position: absolute;
            left: 0;
        }
    }
}
.btn1{
    margin-left: 10px;
    border-radius: 2px;
    color: #71767E;
    background: #F1F1F1;
    font-size: 12px;
    width: 110px;
    min-width: 110px;
}

</style>